import styled, { keyframes } from "styled-components";

export const fade = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const FadeIn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #000000;
  animation: ${fade} 10s normal forwards ease-in-out;
`;

export const Container = styled.div`
  font-family: "alagard";
  font-size: 20px;
  & h1 {
    padding: 0;
    
    font-family: "alagard";
    font-weight: 400;
    font-size: clamp(80px, 10vw, 150px);
    line-height: 1em;
    
  }
`;
export const Center = styled.div`
  position: absolute;
  text-align: center;
  top: 22%;
  left: 26%;
  width: 50%;
  height: 25%;
  @media (max-width: 600px) {
    top: 30%;
    left: 16%;
    text-align: center;
    }
`;

export const BottomLeft = styled.div`
  position: absolute;
  bottom: 5vw;
  left: 5vw;
  width: 30ch;
  max-width: 40%;
  text-align: left;
`;

export const BottomRight = styled.div`
  position: absolute;
  bottom: 5vw;
  right: 5vw;
  width: 35ch;
  max-width: 40%;
  line-height: 1.55em;
  letter-spacing: 0em;
  text-align: right;
`;

export const Hamburger = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 5vw;
  right: 5vw;
  & > div {
    position: relative;
    width: 24px;
    height: 2px;
    background: #000000;
    margin-bottom: 6px;
  }
`;

//make a scrollable component

export const Footer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  height: 100%;
  width: 100%;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
`;
