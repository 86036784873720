import {Container, BottomLeft, BottomRight, Center} from "./styles";

import React from "react";
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import Verga from "./Verga";
import { OrbitControls } from "@react-three/drei";

export default function Overlay() {
  return (
    <Container>
      <Center>
        
          <h1 >
            PIXEL
            
            HOMIES 
          </h1>
        
      </Center>
      <BottomLeft>
        <p>
        Una creacion de <br />{" "}
        <a href="https://sabarobestudios.io">Sabarobe Studios</a>
        </p>
      </BottomLeft>
      <BottomRight>
        <Canvas Canvas>
        <OrbitControls enableZoom={false} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[-2, 5, 2]} />
        <Suspense fallback={null}>
        <Verga />
        </Suspense>
        </Canvas>
        <p>
        COMING SOON... 
        </p>
      </BottomRight>
    </Container>
  );
}
