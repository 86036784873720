import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Verga({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/SS.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 1, 0, 1]}>
        <group rotation={[Math.PI / 180, 380, 45]} scale={0.01}>
          <group scale={50}>
          <mesh geometry={nodes.SS.geometry} material={materials.Silver} position={[0, 0, 0]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={50} />
           </group>
          </group>
        </group>
      </group>
  );
}

useGLTF.preload("/Bigoton.glb");