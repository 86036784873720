import { createRoot } from "react-dom/client";
import { Suspense, useState } from "react";
import "./styles.css";
import Overlay from "./layout/Overlay";
import { FadeIn } from "./layout/styles";
import Model from "./Model";
// Comment the above and uncomment the following to import the WebGL BG lazily for faster loading times
// const Bananas = lazy(() => import('./Bananas'))

function App() {
  const [speed] = useState(1);
  return (
    <>
       <FadeIn />
      <Suspense fallback={null} >
        <Model speed={speed} />
      </Suspense>
        <Overlay />

    </>
    
  );
}

createRoot(document.getElementById("root")).render(<App />);
